import React from 'react';
import { HomePage } from '../components/pages/HomePage';
import { ApplicationProvider } from './ApplicationProvider';

export const Application = () => {
	return (
		<ApplicationProvider>
			<HomePage />
		</ApplicationProvider>
	);
};
