import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MaterialUIThemeProvider } from '@material-ui/styles';
import React from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { theme } from '../components/styles/theme';

type Props = {
	children: React.ReactNode;
};

export const StyleProvider = (props: Props) => {
	const { children } = props;

	return (
		<React.Fragment>
			<CssBaseline />
			<MaterialUIThemeProvider theme={theme}>{children}</MaterialUIThemeProvider>
		</React.Fragment>
	);
};
