import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Switch,
} from '@material-ui/core';
import firebase from 'firebase/app';
import React from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { useRecoilState } from 'recoil';
import { croppedImageSourceState, originalImageSourceState } from '../../state';
import { getCroppedImg } from '../system/getCroppedImage';
import { getImageSize } from '../system/ImageSize';

export const CropDialog = () => {
	const [originalImageSource, setOriginalImageSource] = useRecoilState(originalImageSourceState);
	const [, setCroppedImageSource] = useRecoilState(croppedImageSourceState);
	const [fixedAspect, setFixedAspect] = React.useState(true);

	const { width, height } = getImageSize();

	const aspect = width / height;

	const [crop, setCrop] = React.useState<Crop>(
		fixedAspect
			? {
					aspect,
					unit: '%',
					width: 100,
			  }
			: {
					// unit: '%',
					// width: 50,
					// height: 50,
			  },
	);

	// 画像が変わったら初期化する
	React.useEffect(() => {
		setCrop(
			fixedAspect
				? {
						aspect,
						unit: '%',
						width: 100,
				  }
				: {
						// unit: '%',
						// width: 50,
						// height: 50,
				  },
		);
	}, [originalImageSource, fixedAspect, aspect, setCrop]);

	const open = !!originalImageSource;
	const disabledSubmit = !crop.width;

	const onCancel = React.useCallback(() => {
		setOriginalImageSource('');
	}, [setOriginalImageSource]);

	const onSubmit = React.useCallback(() => {
		const img = new Image();
		img.crossOrigin = 'Anonymous';
		img.src = originalImageSource;
		img.onload = () => {
			setCroppedImageSource(
				getCroppedImg(
					img,
					document.querySelector('img[class="ReactCrop__image"]') as HTMLImageElement,
					crop,
					400,
				),
			);
			setOriginalImageSource('');

			window.location.hash = '#canvas';

			firebase.analytics().logEvent('cropped_image');
		};
	}, [originalImageSource, crop, setCroppedImageSource, setOriginalImageSource]);

	return (
		<Dialog onClose={onCancel} open={open}>
			<DialogTitle>範囲選択</DialogTitle>
			<DialogContent>
				<Box>
					<FormControl fullWidth={true}>
						<FormControlLabel
							control={
								<Switch
									checked={fixedAspect}
									color="primary"
									name="fixed-aspect"
									onChange={(event) => setFixedAspect(Boolean(event.target.checked))}
								/>
							}
							label="アスペクト比の固定"
						/>
					</FormControl>
				</Box>

				<Box alignItems={'center'} display={'flex'} justifyContent={'center'} mt={2}>
					<ReactCrop crop={crop} onChange={(newCrop) => setCrop(newCrop)} src={originalImageSource} />
				</Box>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={onCancel} variant={'outlined'}>
					キャンセル
				</Button>
				<Button color="primary" disabled={disabledSubmit} onClick={onSubmit} variant={'contained'}>
					決定
				</Button>
			</DialogActions>
		</Dialog>
	);
};
