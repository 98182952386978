import { createMuiTheme } from '@material-ui/core/styles';
import 'react-image-crop/dist/ReactCrop.css';

/**
 * https://material.io/resources/color/#!/?view.left=0&view.right=0
 */
export const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#d0021b',
			light: '#2c2c2c',
			dark: '#000000',
			contrastText: '#ffffff',
		},
		// secondary: {
		//     main: '#ffffff',
		//     light: '#ffffff',
		//     dark: '#cccccc',
		//     contrastText: '#000000',
		// },
		type: 'light',
	},
});
