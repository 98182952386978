import firebase from 'firebase/app';
import React from 'react';
import Dropzone from 'react-dropzone';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { originalImageSourceState } from '../../state';
import { FileDropArea } from './FileDropArea';

const Section = styled.section`
	width: 100%;
	height: 100%;
`;

const Root = styled.div`
	width: 100%;
	height: 100%;
`;

export const FileDropZone = () => {
	const [, setImageSource] = useRecoilState(originalImageSourceState);

	// ファイルの選択時
	const onDrop = React.useCallback(
		(acceptedFiles: File[]) => {
			if (acceptedFiles && acceptedFiles.length > 0) {
				const file = acceptedFiles[0];
				const reader = new FileReader();
				reader.onload = (e) => {
					if (e.target && e.target.result) {
						setImageSource(e.target.result.toString());
					}
				};
				reader.readAsDataURL(file);

				firebase.analytics().logEvent('selected_image');
			}
		},
		[setImageSource],
	);

	return (
		<Dropzone accept={'image/*'} maxFiles={1} onDrop={onDrop}>
			{({ getRootProps, getInputProps, isDragActive }) => (
				<Section>
					<Root {...getRootProps()}>
						<input {...getInputProps()} />

						<FileDropArea isDragActive={isDragActive} />
					</Root>
				</Section>
			)}
		</Dropzone>
	);
};
