// const oiginalColors = {
//     offset: [0, 0.25, 0.5, 0.75, 1], // グラデーションの位置
//     rgb: ['33,150,243', '3,169,244', '0,188,212', '0,150,136', '76,175,80'],
//     alpha: [1, 0.8, 0.6, 0.4, 0.2],
//     rgba: [''],   // レンダリング時に生成
//     isReverse: [false] // 同上
// };

const draw = async (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, img: HTMLImageElement) => {
	// 背景パターンの描画
	const canvasPattern = context.createPattern(img, 'repeat');
	if (canvasPattern) {
		context.fillStyle = canvasPattern;
		context.fillRect(0, 0, canvas.width, canvas.height);
	}

	const colors = {
		offset: [0, 0.25, 0.5, 0.75, 1], // グラデーションの位置
		rgb: ['33,150,243', '3,169,244', '0,188,212', '0,150,136', '76,175,80'],
		alpha: [1, 0.8, 0.6, 0.4, 0.2],
		rgba: [''], // レンダリング時に生成
		isReverse: [false], // 同上
	};
	// 渡すグラデーションのrgbaを生成する
	for (let i = 0, l = colors.rgb.length; i < l; i++) {
		colors.rgba[i] = 'rgba(' + colors.rgb[i] + ', ' + colors.alpha[i] + ')';

		// 一定の範囲で繰り返す
		if (colors.alpha[i] < 0.2) {
			colors.isReverse[i] = false;
			colors.alpha[i] = 0.2;
		}
		if (colors.alpha[i] > 1) {
			colors.isReverse[i] = true;
			colors.alpha[i] = 1;
		}

		// 配列に対応するisReverseをもとに増減
		if (colors.isReverse[i]) {
			colors.alpha[i] -= 0.01;
		} else {
			colors.alpha[i] += 0.01;
		}
	}

	// const blendMode = 'multiply';
	// context.globalCompositeOperation = blendMode;
	context.beginPath();
	const grad = context.createLinearGradient(0, 0, canvas.width, canvas.height);
	for (let i = 0, l = colors.offset.length; i < l; i++) {
		grad.addColorStop(colors.offset[i], colors.rgba[i]);
	}
	context.fillStyle = grad;
	context.rect(0, 0, canvas.width, canvas.height);
	context.fill();
};

/**
 * 背景のキラキラ描画
 *
 * https://qiita.com/nekoneko-wanwan/items/a6db5995029328f03720
 */
export const drawBackground = (
	canvas: HTMLCanvasElement,
	context: CanvasRenderingContext2D,
	pattern: HTMLImageElement,
) => {
	draw(canvas, context, pattern);
};
