export const createImageAsync = (src: string): Promise<HTMLImageElement | null> => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.crossOrigin = 'Anonymous';
		img.src = src;
		img.onload = () => {
			resolve(img);
		};
		img.onerror = (error) => {
			console.error(error);
			resolve(null);
		};
	});
};
