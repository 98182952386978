import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { loadingState } from '../../state';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.tooltip + 2,
		color: '#fff',
	},
}));

export const LoadingOverlay = () => {
	const classes = useStyles();
	const loading = useRecoilValue(loadingState);

	if (!loading) {
		return null;
	}

	return (
		<Backdrop className={classes.backdrop} open={true}>
			<CircularProgress />
		</Backdrop>
	);
};
