import { createImageAsync } from './createImageAsync';

/**
 * パターンとなる1マスの円錐型グラデーション画像を作成
 */
export const createPatterntAsync = async (originalCanvas: HTMLCanvasElement): Promise<HTMLImageElement> => {
	const temporaryCanvas = document.getElementById('temporary-canvas') as HTMLCanvasElement;
	const temporaryContext = temporaryCanvas.getContext('2d');
	if (!temporaryContext) {
		throw new Error('Context does not exists.');
	}

	const patternWidth = originalCanvas.width / 10;
	const patternHeight = originalCanvas.height / 10;

	temporaryCanvas.width = patternWidth;
	temporaryCanvas.height = patternHeight;
	let isReverse = false;
	let lightness = 0;
	const _csWidth = temporaryCanvas.width;
	const _csHeight = temporaryCanvas.height;

	/* 中心に移動 */
	temporaryContext.translate(_csWidth / 2, _csHeight / 2);
	temporaryContext.lineWidth = 1;

	/* 1本ずつ線を引いていき、回転させることで円錐を実現 */
	for (let i = 0; i < 360; i++) {
		// 45度ごとに折り返し地点を定める
		if (i % 45 === 0) {
			if (isReverse) {
				isReverse = false;
			} else {
				isReverse = true;
			}
		}
		// isReverseに応じて色の輝度を変更
		if (isReverse) {
			lightness += 1;
		} else {
			lightness -= 1;
		}

		// 輝度の上限・下限を設定
		if (lightness < 70) {
			lightness = 70;
		}
		if (lightness > 100) {
			lightness = 100;
		}

		temporaryContext.save();
		temporaryContext.rotate((Math.PI * i) / 180);
		temporaryContext.beginPath();
		temporaryContext.moveTo(0, 0);
		temporaryContext.strokeStyle = 'hsl(0, 0%, ' + lightness + '%)';
		temporaryContext.lineTo(0, _csWidth);
		temporaryContext.stroke();
		temporaryContext.closePath();
		temporaryContext.restore();
	}

	const source = temporaryCanvas.toDataURL();

	const img = await createImageAsync(source);

	if (!img) {
		throw new Error(`Invalid`);
	}
	return img;
};
