import React from 'react';
import firebase from 'firebase/app';

/**
 * キャンバス画像をダウンロードするhooks
 */
export const useDownloadCanvasImage = () => {
	return React.useCallback(() => {
		const canvas = document.getElementById('canvas') as HTMLCanvasElement;
		const context = canvas.getContext('2d');
		if (!context) {
			throw new Error('Context does not exists.');
		}

		const name = `kirakira_${Date.now()}.jpg`;
		const anchor: any = document.createElement('a');
		anchor.download = name;
		anchor.href = canvas.toDataURL('image/jpg');
		document.body.appendChild(anchor);
		anchor.click();
		anchor.parentNode.removeChild(anchor);

		firebase.analytics().logEvent('download_image');
	}, []);
};
