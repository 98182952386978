import { atom } from 'recoil';
import { TextColorType } from './components/system/TextColorType';
import { TextureType } from './components/system/TextureType';

export const nameState = atom({
	key: 'name',
	default: 'ナ・マエ',
});

export const sizeState = atom({
	key: 'size',
	default: 1,
});

export const originalImageSourceState = atom({
	key: 'originalImageSource',
	default: '',
});

export const croppedImageSourceState = atom({
	key: 'croppedImageSource',
	default: '',
});

export const tweetState = atom({
	key: 'tweet',
	default: `#キラキラシールメーカー で作ったよ！

http://kirakira.cgig.jp/`,
});

export const loadingState = atom({
	key: 'loading',
	default: false,
});

export const openTermsState = atom({
	key: 'openTerms',
	default: false,
});

export const imageDataState = atom({
	key: 'imageData',
	default: '',
});

export const textColorTypeState = atom<TextColorType>({
	key: 'textColorType',
	default: 'red',
});

export const textureTypeState = atom<TextureType>({
	key: 'textureType',
	default: '001',
});
