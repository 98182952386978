import { FormControl, Grid, Paper } from '@material-ui/core';
import React from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { textColorTypeState } from '../../state';
import { getTextColorSelectionBackground, textColorTypes } from '../system/TextColorType';

const ColorSelection = styled(
	({
		selected,
		...rest
	}: { selected: boolean; background: string } & React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	>) => <div {...rest} />,
)`
	width: 100%;
	height: 30px;
	background: ${(p) => p.background};
	${(p) => (p.selected ? 'border: solid 2px red;' : 'border: solid 2px black;')}
	cursor: pointer;
`;

/**
 * テキストの色選択
 */
export const TextColorSelectField = () => {
	const [textColorType, setTextColorType] = useRecoilState(textColorTypeState);

	return (
		<FormControl component="fieldset" fullWidth={true}>
			<Grid container spacing={3}>
				{textColorTypes.map((x) => {
					const selected = textColorType === x;
					const background = getTextColorSelectionBackground(x);

					return (
						<Grid item key={x} xs={2}>
							<Paper>
								<ColorSelection
									background={background}
									onClick={() => setTextColorType(x)}
									selected={selected}
								/>
							</Paper>
						</Grid>
					);
				})}
			</Grid>
		</FormControl>
	);
};
