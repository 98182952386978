// https://riptutorial.com/html5-canvas/example/18742/rendering-text-along-an-arc-

import { getTextColors, getTextOutlineColor, TextColorType } from '../../system/TextColorType';

const FILL = 0;
const STROKE = 1;
let renderType = FILL;
const multiplyCurrentTransform = true;

const circleText = (
	context: CanvasRenderingContext2D,
	text: string,
	x: number,
	y: number,
	radius: number,
	start: number,
	onPreRender?: (index: number) => void,
	end?: number,
	forward: boolean | null = true,
) => {
	// let  pA, a, aw, wScale;
	let wScale, dir, a;
	let pA = 0;
	if (text.trim() === '' || context.globalAlpha === 0) {
		// dont render empty string or transparent
		return;
	}
	if (isNaN(x) || isNaN(y) || isNaN(radius) || isNaN(start) || (end !== undefined && end !== null && isNaN(end))) {
		//
		throw TypeError('circle text arguments requires a number for x,y, radius, start, and end.');
	}
	const aligned = context.textAlign; // save the current textAlign so that it can be restored at end
	dir = forward ? 1 : forward === false ? -1 : 1; // set dir if not true or false set forward as true
	const pAS = 1 / radius; // get the angular size of a pixel in radians
	const textWidth = context.measureText(text).width; // get the width of all the text
	if (end !== undefined && end !== null) {
		// if end is supplied then fit text between start and end
		const pA = ((end - start) / textWidth) * dir;
		wScale = (pA / pAS) * dir;
	} else {
		// if no end is supplied correct start and end for alignment
		// if forward is not given then swap top of circle text to read the correct direction
		if (forward === null || forward === undefined) {
			if (((start % (Math.PI * 2)) + Math.PI * 2) % (Math.PI * 2) > Math.PI) {
				dir = -1;
			}
		}
		pA = -pAS * dir;
		wScale = -1 * dir;
		switch (aligned) {
			case 'center': // if centered move around half width
				start -= (pA * textWidth) / 2;
				end = start + pA * textWidth;
				break;
			case 'right': // intentionally falls through to case "end"
			case 'end':
				end = start;
				start -= pA * textWidth;
				break;
			case 'left': // intentionally falls through to case "start"
			case 'start':
				end = start + pA * textWidth;
		}
	}

	context.textAlign = 'center'; // align for rendering
	a = start; // set the start angle
	for (let i = 0; i < text.length; i += 1) {
		onPreRender && onPreRender(i);

		// for each character
		const aw = context.measureText(text[i]).width * pA; // get the angular width of the text
		const xDx = Math.cos(a + aw / 2); // get the yAxies vector from the center x,y out
		const xDy = Math.sin(a + aw / 2);
		if (multiplyCurrentTransform) {
			// transform multiplying current transform
			context.save();
			if (xDy < 0) {
				// is the text upside down. If it is flip it
				context.transform(-xDy * wScale, xDx * wScale, -xDx, -xDy, xDx * radius + x, xDy * radius + y);
			} else {
				context.transform(-xDy * wScale, xDx * wScale, xDx, xDy, xDx * radius + x, xDy * radius + y);
			}
		} else {
			if (xDy < 0) {
				// is the text upside down. If it is flip it
				context.setTransform(-xDy * wScale, xDx * wScale, -xDx, -xDy, xDx * radius + x, xDy * radius + y);
			} else {
				context.setTransform(-xDy * wScale, xDx * wScale, xDx, xDy, xDx * radius + x, xDy * radius + y);
			}
		}

		if (renderType === FILL) {
			context.fillText(text[i], 0, 0); // render the character
		} else {
			context.strokeText(text[i], 0, 0); // render the character
		}
		if (multiplyCurrentTransform) {
			// restore current transform
			context.restore();
		}
		a += aw; // step to the next angle
	}
	// all done clean up.
	if (!multiplyCurrentTransform) {
		context.setTransform(1, 0, 0, 1, 0, 0); // restore the transform
	}
	context.textAlign = aligned; // restore the text alignment
};

/**
 * テキストの描画
 */
export const drawText = (
	canvas: HTMLCanvasElement,
	context: CanvasRenderingContext2D,
	text: string,
	textColorType: TextColorType,
) => {
	const rad = -canvas.height;
	const fontSize = 90;
	const centerX = canvas.width / 2;
	const centerY = canvas.height * 1.2;
	const start = Math.PI * -1.5;

	const textColors = getTextColors(textColorType);

	context.font = fontSize + "px 'Yusei Magic'";
	context.textAlign = 'center';
	context.textBaseline = 'bottom';
	context.lineWidth = 12;

	renderType = STROKE;
	circleText(context, text, centerX, centerY, rad, start, (index) => {
		context.fillStyle = textColors[index % textColors.length];
		context.strokeStyle = getTextOutlineColor(textColorType);
	});

	renderType = FILL;
	circleText(context, text, centerX, centerY, rad, start, (index) => {
		context.fillStyle = textColors[index % textColors.length];
		context.strokeStyle = getTextOutlineColor(textColorType);
	});
};
