import { AppBar, Container, Toolbar, Typography, TypographyProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Bar = styled(AppBar)`
	background: #fff;
`;

const Title = styled((props: TypographyProps) => <Typography {...props} variant={'h1'} />)`
	font-size: 24px;
	color: #000;
`;

export const NavigationBar = () => {
	return (
		<Bar position="static">
			<Toolbar>
				<Container disableGutters={true} maxWidth="sm">
					<Title>キラキラシールメーカー</Title>
				</Container>
			</Toolbar>
		</Bar>
	);
};
