import { Box, Button, Container, FormHelperText, Grid, Paper, Typography, TypographyProps } from '@material-ui/core';
import TwitterMaterialIcon from '@material-ui/icons/Twitter';
import React from 'react';
import {
	FacebookIcon,
	FacebookShareButton,
	LineIcon,
	LineShareButton,
	TwitterIcon,
	TwitterShareButton,
} from 'react-share';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { croppedImageSourceState } from '../../state';
import { useDownloadCanvasImage } from '../hooks/useDownloadCanvasImage';
import { useOpenCanvasImage } from '../hooks/useOpenCanvasImage';
import { useTermsClick } from '../hooks/useTermsClick';
import { useTweet } from '../hooks/useTweet';
import { AdSenseBanner } from '../molecules/AdSenseBanner';
import { AspectView } from '../molecules/AspectView';
import { NameTextField } from '../molecules/NameTextField';
import { SizeSlider } from '../molecules/SizeSlider';
import { TextColorSelectField } from '../molecules/TextColorSelectField';
import { TextureSelectField } from '../molecules/TextureSelectField';
import { TweetTextField } from '../molecules/TweetTextField';
import { Canvas } from '../organisms/Canvas';
import { CropDialog } from '../organisms/CropDialog';
import { FileDropZone } from '../organisms/FileDropZone';
import { Footer } from '../organisms/Footer';
import { NavigationBar } from '../organisms/NavigationBar';
import { TermsSection } from '../organisms/TermsSection';
import { isSmartphone } from '../system/isSmartphone';

const View = styled.div`
	background: #d0021b;
`;

const StyledPaper = styled(Paper)`
	background: #f8e71c;
	color: #000;
`;

const Title = styled((props: TypographyProps) => <Typography {...props} gutterBottom={true} variant={'h3'} />)`
	font-size: 24px;
`;

// TODO: 背景パターン選択できるように https://www.google.com/search?q=%E3%83%93%E3%83%83%E3%82%AF%E3%83%AA%E3%83%9E%E3%83%B3+%E8%83%8C%E6%99%AF&source=lnms&tbm=isch&sa=X&ved=2ahUKEwi6koiegcTuAhVOMd4KHWOFDTgQ_AUoAXoECBQQAw&biw=1178&bih=832&dpr=2
// TODO: 文字パターン選択できるように
// TODO: 文字は2段いけるように
// TODO: 文字の周囲に謎の黒いトゲ
export const HomePage = () => {
	const croppedImageSource = useRecoilValue(croppedImageSourceState);
	const disabledSubmit = !croppedImageSource;

	const onDownload = useDownloadCanvasImage();
	const onOpenWindow = useOpenCanvasImage();
	const onClickTerms = useTermsClick();
	const onTweet = useTweet();

	return (
		<View>
			<NavigationBar />
			<Box py={4}>
				<Container disableGutters={true} maxWidth="sm">
					<StyledPaper>
						<Box pb={4} pt={2}>
							<Container maxWidth="sm">
								<Box>
									<Title id="select">画像を選択</Title>

									<AspectView height={1} width={3}>
										<FileDropZone />
									</AspectView>

									<Box mt={1}>
										<FormHelperText>透過画像をおすすめします</FormHelperText>
									</Box>
								</Box>
							</Container>
						</Box>
					</StyledPaper>
				</Container>

				<Container disableGutters={true} maxWidth="sm">
					<Box mb={4} mt={4}>
						<AdSenseBanner slotId={'5200231889'} />
					</Box>
				</Container>

				<Box pb={4}>
					<Container disableGutters={true} maxWidth="sm">
						<StyledPaper>
							<Canvas />

							<Box pb={4}>
								<Container maxWidth="sm">
									<Box mt={2}>
										<NameTextField />
									</Box>

									<Box mt={4}>
										<TextColorSelectField />
									</Box>

									<Box mt={4}>
										<TextureSelectField />
									</Box>

									<Box mt={4}>
										<SizeSlider />
									</Box>

									<Box mt={4}>
										<FormHelperText>
											<a href="#terms" onClick={onClickTerms}>
												利用規約
											</a>
											に同意の上
										</FormHelperText>
										<Button
											color="primary"
											disabled={disabledSubmit}
											fullWidth={true}
											onClick={isSmartphone() ? onOpenWindow : onDownload}
											variant="contained"
										>
											画像をダウンロード
										</Button>

										<Box mt={1}>
											<FormHelperText>
												※
												本サービスで使用する画像はサーバーへ送信・保存されません。安心してご利用ください
											</FormHelperText>
											<FormHelperText>
												スマホをご利用の方は画像を長押しして保存してください
											</FormHelperText>
										</Box>
									</Box>

									<Box mt={4}>
										<Box>
											<TweetTextField />
										</Box>

										<Box mt={4}>
											<Button
												color="primary"
												disabled={disabledSubmit}
												fullWidth={true}
												onClick={onTweet}
												startIcon={<TwitterMaterialIcon />}
												variant="contained"
											>
												Twitter連携して投稿する
											</Button>
										</Box>
									</Box>

									<Box mt={6}>
										<Grid container spacing={3}>
											<Grid item xs={3} />
											<Grid item xs={2}>
												<FacebookShareButton url={'http://kirakira.cgig.jp/'}>
													<FacebookIcon round size={'100%'} />
												</FacebookShareButton>
											</Grid>

											<Grid item xs={2}>
												<TwitterShareButton
													title={'#キラキラシールメーカー\n\n'}
													url="http://kirakira.cgig.jp/"
												>
													<TwitterIcon round size={'100%'} />
												</TwitterShareButton>
											</Grid>

											<Grid item xs={2}>
												<LineShareButton url={'http://kirakira.cgig.jp/'}>
													<LineIcon round size={'100%'} />
												</LineShareButton>
											</Grid>
										</Grid>
									</Box>
								</Container>
							</Box>
						</StyledPaper>
					</Container>

					<Container disableGutters={true} maxWidth="sm">
						<Box mt={4}>
							<AdSenseBanner slotId={'2574068546'} />
						</Box>
					</Container>

					<Container disableGutters={true} maxWidth="sm">
						<Box mt={4}>
							<TermsSection />
						</Box>
					</Container>
				</Box>
			</Box>

			<CropDialog />

			<Footer />
		</View>
	);
};
