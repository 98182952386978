import { FormControl, Grid } from '@material-ui/core';
import React from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { textureTypeState } from '../../state';
import { getTexturePath, textureTypes } from '../system/TextureType';
import { AspectView } from './AspectView';

const ColorSelection = styled(
	({
		selected,
		...rest
	}: { selected: boolean; background: string } & React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	>) => <div {...rest} />,
)`
	width: 100%;
	height: 100%;
	background: ${(p) => p.background};
	${(p) => (p.selected ? 'border: solid 2px red;' : 'border: solid 2px black;')}
	background-size: contain;
	cursor: pointer;
`;

/**
 * 背景画像選択
 */
export const TextureSelectField = () => {
	const [textureType, setTextureType] = useRecoilState(textureTypeState);

	return (
		<FormControl component="fieldset" fullWidth={true}>
			<Grid container spacing={3}>
				{textureTypes.map((x) => {
					const selected = textureType === x;
					const background = `url(${getTexturePath(x)})`;

					return (
						<Grid item key={x} xs={2}>
							<AspectView height={100} width={100}>
								<ColorSelection
									background={background}
									onClick={() => setTextureType(x)}
									selected={selected}
								/>
							</AspectView>
						</Grid>
					);
				})}
			</Grid>
		</FormControl>
	);
};
