import { drawBackground } from './drawBackground';
import { createImageAsync } from './createImageAsync';
import { drawImage } from './drawImage';
import { createPatterntAsync } from './createPatterntAsync';
import { drawText } from './drawText';
import { CanvasParameter } from '../../system/CanvasParameter';
import { getTexturePath } from '../../system/TextureType';

export const drawCanvas = async (params: CanvasParameter): Promise<string> => {
	const { imageSource, name, sizeRate, textColorType, textureType } = params;

	const canvas = document.getElementById('canvas') as HTMLCanvasElement;
	const context = canvas.getContext('2d');
	if (!context) {
		throw new Error('Context does not exists.');
	}

	let img: HTMLImageElement | null = null;
	let textureImg: HTMLImageElement | null = null;

	if (imageSource) {
		img = await createImageAsync(imageSource);
	}

	if (textureType) {
		const path = getTexturePath(textureType);
		textureImg = await createImageAsync(`${path}`);
	}

	const pattern = await createPatterntAsync(canvas);

	// 準備完了したので描画する
	context.restore();
	context.clearRect(0, 0, canvas.width, canvas.height);

	// 背景を描画
	if (textureImg) {
		context.drawImage(textureImg, 0, 0, canvas.width, canvas.height);
	} else {
		drawBackground(canvas, context, pattern);
	}

	// 画像を描画
	if (img) {
		drawImage(canvas, context, img, sizeRate);
	}

	// テキストの描画
	drawText(canvas, context, name, textColorType);

	// base64にして返す
	return canvas.toDataURL('image/jpg');
};
