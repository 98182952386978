import { FormControl, TextField } from '@material-ui/core';
import React from 'react';
import { useRecoilState } from 'recoil';
import { nameState } from '../../state';

export const NameTextField = () => {
	const [name, setName] = useRecoilState(nameState);

	const onChange = React.useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement>) => {
			setName(event.target.value);
		},
		[setName],
	);

	return (
		<FormControl fullWidth={true}>
			<TextField label={'名前'} onChange={onChange} required={true} value={name} />
		</FormControl>
	);
};
