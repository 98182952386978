import React from 'react';
import { RecoilRoot } from 'recoil';
import { LoadingOverlay } from '../components/organisms/LoadingOverlay';
import { FirebaseProvider } from './FirebaseProvider';
import { StyleProvider } from './StyleProvider';
import { WebFontProvider } from './WebFontProvider';

type Props = {
	children: React.ReactNode;
};
export const ApplicationProvider = (props: Props) => {
	const { children } = props;

	return (
		<StyleProvider>
			<RecoilRoot>
				<WebFontProvider />
				<FirebaseProvider />
				{children}

				<LoadingOverlay />
			</RecoilRoot>
		</StyleProvider>
	);
};
