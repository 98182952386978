import { FormControl, Slider, Typography } from '@material-ui/core';
import React from 'react';
import { useRecoilState } from 'recoil';
import { sizeState } from '../../state';

export const SizeSlider = () => {
	const [size, setSize] = useRecoilState(sizeState);

	const onChange = React.useCallback(
		(_: React.ChangeEvent<any>, value: number | number[]) => {
			setSize(Number(value));
		},
		[setSize],
	);

	return (
		<FormControl fullWidth={true}>
			<Typography gutterBottom>画像サイズ</Typography>
			<Slider marks max={1} min={0.1} onChange={onChange} step={0.025} value={size} />
		</FormControl>
	);
};
