import { FormControl, TextField } from '@material-ui/core';
import React from 'react';
import { useRecoilState } from 'recoil';
import { tweetState } from '../../state';

export const TweetTextField = () => {
	const [tweet, setTweet] = useRecoilState(tweetState);

	const onChange = React.useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement>) => {
			setTweet(event.target.value);
		},
		[setTweet],
	);

	return (
		<FormControl fullWidth={true}>
			<TextField label={'Tweet'} multiline onChange={onChange} required={true} rowsMax={4} value={tweet} />
		</FormControl>
	);
};
