import React from 'react';
import styled from 'styled-components';

const View = styled.div<{ aspect: number }>`
	position: relative;
	width: 100%;
	height: 0;
	padding-top: ${(p) => p.aspect}%;

	& > :first-child {
		position: absolute;
		top: 0;
	}
`;

export type Props = {
	width: number;
	height: number;
	className?: string;
	children: React.ReactNode;
};

export const AspectView = (props: Props) => {
	const { width, height, className, children } = props;

	const aspect = (height / width) * 100;

	return (
		<View aspect={aspect} className={className}>
			{children}
		</View>
	);
};
