import React from 'react';
import firebase from 'firebase/app';

/**
 * キャンバス画像で新しいWindowを開く
 */
export const useOpenCanvasImage = () => {
	return React.useCallback(() => {
		const canvas = document.getElementById('canvas') as HTMLCanvasElement;
		const url = canvas.toDataURL('image/jpeg');
		const win = window.open('', '_blank');

		let html = '';

		html += '<html>';
		html += '<body style="margin:0!important">';
		html += `<img width="100%" style="object-fit:contain;" src="${url}" />`;
		html += '</body>';
		html += '</html>';

		setTimeout(() => {
			win?.document?.write(html);
		}, 0);

		firebase.analytics().logEvent('open_image_window');
	}, []);
};
