import React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { imageDataState } from '../../state';
import { useCanvasDrawer } from '../hooks/useCanvasDrawer';
import { AspectView } from '../molecules/AspectView';
import { getImageSize } from '../system/ImageSize';

const MainCanvas = styled.canvas`
	position: relative;
	width: 100%;
	height: 100%;
`;

const WorkCanvas = styled.canvas`
	display: none;
`;

const FillImage = styled.img`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
`;

export const Canvas = () => {
	const imageData = useRecoilValue(imageDataState);

	const { width, height } = getImageSize();

	useCanvasDrawer();

	return (
		<div>
			<WorkCanvas id="temporary-canvas" />

			<AspectView height={height} width={width}>
				<MainCanvas height={height} id="canvas" width={width} />
				<FillImage alt={'完成画像'} src={imageData} style={{ display: imageData ? 'block' : 'none' }} />
			</AspectView>
		</div>
	);
};
