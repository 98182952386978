import React from 'react';
import WebFont from 'webfontloader';

export const WebFontProvider = () => {
	const [initialized, setInitialized] = React.useState(false);

	if (!initialized) {
		setInitialized(true);

		WebFont.load({
			google: {
				families: ['Roboto:300,400,500,700', 'Yusei+Magic'],
			},
			active: () => {
				sessionStorage.fonts = true;
			},
		});
	}

	return null;
};
