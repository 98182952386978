import React from 'react';
import { useSetRecoilState } from 'recoil';
import { imageDataState } from '../../../state';
import { useCanvasParameter } from '../useCanvasParameter';
import { drawCanvas } from './drawCanvas';

/**
 * Canvasの描画
 */
export const useCanvasDrawer = () => {
	const params = useCanvasParameter();
	const setImageDataState = useSetRecoilState(imageDataState);

	React.useEffect(() => {
		// const imageSource = "https://1.bp.blogspot.com/-VthzAuEo8fc/X96mhYv33UI/AAAAAAABdBs/HXCc0J0WsHUMSuQ00UZ5UuLPUXatMIq-wCNcBGAsYHQ/s350/onepiece01_luffy2.png"

		const render = () => {
			drawCanvas(params).then((data) => {
				setImageDataState(data);
			});
		};

		render();

		// Webfontのために再描画する
		const timeoutId = setTimeout(render, 3000);

		return () => clearTimeout(timeoutId);
	}, [params, setImageDataState]);
};
