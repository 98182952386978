import 'firebase/analytics';
import firebase from 'firebase/app';
import React from 'react';

export const FirebaseProvider = React.memo(() => {
	try {
		firebase.initializeApp({
			apiKey: 'AIzaSyBnL-TcUMA1oJog-tmGgR0K4q3-xliiQbs',
			authDomain: 'cgig-tools.firebaseapp.com',
			projectId: 'cgig-tools',
			storageBucket: 'cgig-tools.appspot.com',
			messagingSenderId: '255034917378',
			appId: '1:255034917378:web:2ea679515a15a8a6d6dd1b',
			measurementId: 'G-RPGS0B8YH9',
		});
	} catch (error) {}

	firebase.app().analytics().setCurrentScreen('/');

	return null;
});
