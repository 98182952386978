/**
 * 指定の画像を描画
 *
 * 高さ固定
 */
export const drawImage = (
	canvas: HTMLCanvasElement,
	context: CanvasRenderingContext2D,
	img: HTMLImageElement,
	rate: number,
) => {
	const imageAspect = img.width / img.height;

	const width = canvas.height * imageAspect * rate;
	const height = canvas.height * rate;

	context.drawImage(img, (canvas.width - width) / 2, (canvas.height - height) / 2, width, height);
};
