export const textColorTypes = [
	'red',
	'blue',
	'yellow',
	'brown',
	'green',
	'purple',
	'rainbow',
	'white',
	'black',
] as const;

export type TextColorType = typeof textColorTypes[number];

export const getTextColors = (type: TextColorType): string[] => {
	switch (type) {
		case 'red':
			return ['rgb(229, 1, 19)'];

		case 'blue':
			return ['rgb(0, 166, 233)'];

		case 'yellow':
			return ['rgb(255, 240, 0)'];

		case 'brown':
			return ['rgb(168, 94, 36)'];

		case 'green':
			return ['rgb(126, 211, 33)'];

		case 'purple':
			return ['rgb(144, 19, 254)'];

		case 'rainbow':
			return ['#c24145', '#d47a2e', '#ded448', '#55b544', '#72cbfa', '#5478f6', '#7161ac'];

		case 'white':
			return ['rgb(255, 255, 255)'];

		case 'black':
			return ['rgb(0, 0, 0)'];

		default:
			throw new Error(`Invalid color type`);
	}
};

export const getTextOutlineColor = (type: TextColorType): string => {
	switch (type) {
		case 'red':
		case 'blue':
		case 'yellow':
		case 'brown':
		case 'green':
		case 'purple':
		case 'rainbow':
		case 'white':
			return '#000';

		case 'black':
			return '#fff';

		default:
			throw new Error(`Invalid color type`);
	}
};

export const getTextColorSelectionBackground = (type: TextColorType): string => {
	switch (type) {
		case 'red':
		case 'blue':
		case 'yellow':
		case 'brown':
		case 'green':
		case 'purple':
		case 'white':
		case 'black':
			return getTextColors(type)[0];

		case 'rainbow':
			return 'linear-gradient(90deg, #c24145 0%, #c24145 9.82%, #d47a2e 9.82%, #d47a2e 25.89%, #ded448 25.89%, #ded448 41.96%, #55b544 41.96%, #55b544 58.03%, #72cbfa 58.03%, #72cbfa 74.1%, #5478f6 74.1%, #5478f6 90.17%, #7161ac 90.17%, #7161ac 100%);';

		default:
			throw new Error(`Invalid color type`);
	}
};
