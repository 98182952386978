export const isSmartphone = (): boolean => {
	const userAgent = navigator.userAgent.toLowerCase();
	const iOS = userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1;
	if (iOS) {
		return true;
	}

	// iOS13以降のiPad対応
	// https://www.gravity-works.jp/gravica/web/js/004655.html
	if (userAgent.indexOf('macintosh') > -1 && 'ontouchend' in document) {
		return true;
	}

	if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
		return true;
	}

	return false;
};
