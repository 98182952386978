import { useRecoilValue } from 'recoil';
import { croppedImageSourceState, nameState, sizeState, textColorTypeState, textureTypeState } from '../../state';
import { CanvasParameter } from '../system/CanvasParameter';

/**
 * キャンバス設定の値を取得
 */
export const useCanvasParameter = (): CanvasParameter => {
	const imageSource = useRecoilValue(croppedImageSourceState);
	const name = useRecoilValue(nameState);
	const sizeRate = useRecoilValue(sizeState);
	const textColorType = useRecoilValue(textColorTypeState);
	const textureType = useRecoilValue(textureTypeState);

	return {
		imageSource,
		name,
		sizeRate,
		textColorType,
		textureType,
	};
};
